exports.onRouteUpdate = ({ location }) => {
  if (location.hash) {
    // console.log(location)
    setTimeout(() => {
      document.querySelector(`${location.hash}`).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      })
    }, 500)
  }
}
